import React, { useContext } from "react"
import { Link, useNavigate } from "react-router-dom"
import lockIcon from "../../assets/svg/lock.svg"
import UserProfile from "../../assets/svg/userProfile.svg"
import cell from "../../assets/svg/cell.svg"
import "rsuite/dist/rsuite.min.css"
import AppContext from "../../Context"
import AppButton from "../AppButton"
import { COLORS } from "../../constants"
import price from "../../assets/images/price.png"
import MenuIcon from "@mui/icons-material/Menu"

import {
  Drawer, ListItem,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText, Box, Grid, IconButton,
} from "@mui/material"

export default function HomeHeader({ home }) {
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [state, setState] = React.useState({
    openDrawer: false
  })
  const { openDrawer } = state
  let userData = localStorage.getItem("userData")
  const { user, setUser } = useContext(AppContext)
  const toggleDrawer = open => {
    setState({ ...state, openDrawer: open })
  }
  userData = JSON.parse(userData)
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const logout = () => {
    localStorage.removeItem("token")
    localStorage.removeItem("user")
    setUser(null)
    navigate("/")
  }

  const open = Boolean(anchorEl)
  const id = open ? "simple-popover" : undefined

  return (
    <div>
      <header className="login_nav home_nav">
        {home && (
          <div className="phoneHeader">
            <div className={`container rightText`}>
              <div>
                <img src={cell} /> +1299384567
              </div>
            </div>
          </div>
        )}
        <div className={`container`}>
          <Grid container className="ph-0 col-12">
            <Grid container className="ph-0 mt-2 mb-2">

              <Grid container item md={8} xs={12} className="ph-0 displayFlexMobile justify-content-between align-items-center">
                <li>
                  <Link to={"/"}>
                    <div className="d-flex align-items-center">
                      <img src={price} className="logo" />
                      <div className="logoText" />
                    </div>
                  </Link>
                </li>
                <div className="displayFlexMobile" style={{ marginRight: -20 }}>
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={() => toggleDrawer(true)}
                    edge="start"
                    sx={{ mr: 2, ...(openDrawer && { display: "none" }) }}
                  >
                    <MenuIcon style={{ color: '#fff' }} />
                  </IconButton>
                </div>
              </Grid>
              <Grid container item md={8} xs={12} className="ph-0 displayMobileHide align-items-center">
                <li>
                  <Link to={"/"}>
                    <div className="d-flex align-items-center">
                      <img src={price} className="logo" />
                      <div className="logoText" />
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to={"/about-us"}>
                    <div className="listpool ml-5">About Us</div>
                  </Link>
                </li>
                <li>
                  <Link to={"/contact-us"}>
                    <div className="listpool ml-5">Contact Us</div>
                  </Link>
                </li>
                <li>
                  <div className="listpool ml-5">Resources</div>
                </li>
              </Grid>


              <Grid container item md={4} xs={12} justifyContent={'flex-end'} className="ph-0 mtm-1 align-items-center">
                {user ? (
                  <>
                    <li>
                      <div className="listpool mr-4">{user?.name}</div>
                    </li>
                    {/* <li onClick={handleClick} className="listpool mr-4">
                      <img src={UserProfile} className={"c-pointer"} />
                    </li> */}
                  </>
                ) : (
                  <>
                    <Link to={"/login"}>
                      <li>
                        <div className="listpool mr-4">
                          <img src={lockIcon} style={{ marginBottom: 2 }} /> Log
                          in
                        </div>
                      </li>
                    </Link>
                    <Link to={"/signup"}>
                      <li>
                        <AppButton
                          title={"Sign up"}
                          backgroundColor={COLORS.primary}
                          color={COLORS.white}
                          height={40}
                          className="mr-4"
                        />
                      </li>
                    </Link>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Drawer
            anchor={"left"}
            open={openDrawer}
            onClose={() => toggleDrawer(false)}
          >
            <Box
              sx={{
                width: 250
              }}
              role="presentation"
            >
              <List>
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => navigate("/about-us")}
                  >
                    <ListItemText primary={"About Us"} />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton onClick={() => navigate("/contact-us")}>
                    <ListItemText primary={"Contact Us"} />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton >
                    <ListItemText primary={"Resources"} />
                  </ListItemButton>
                </ListItem>
              </List>
            </Box>
          </Drawer>
        </div>
      </header>
    </div>
  )
}
