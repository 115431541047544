// @ts-nocheck
/* eslint-disable no-empty-pattern */
import React, { useState } from "react"
import { AppButton, AppInput, Layout } from "../../components"
import { Grid } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { changePassword1 } from "../../api/auth"
import eyeIcon from "../../assets/svg/eye.svg"
import eyeCLose from "../../assets/images/closeEYE.jpg"
import { COLORS } from "../../constants"

export default function ChangePassword({}) {
  const navigate = useNavigate()
  const [state, setState] = useState({
    oldpassword: "",
    password_1: "",
    password_2: "",
    loadingSignup: false,
    isShow: false,
    isShow1: false,
    isShow2: false
  })

  const {
    oldpassword,
    loadingSignup,
    password_1,
    password_2,
    isShow,
    isShow1,
    isShow2
  } = state

  const handleChange = (key, value) => {
    setState(pre => ({
      ...pre,
      [key]: value
    }))
  }

  const handleSignup = async () => {
    try {
      handleChange("loadingSignup", true)
      const payload = {
        old_password: oldpassword,
        new_password1: password_1,
        new_password2: password_2
      }
      const res = await changePassword1(payload)
      alert("Password has been changed")
      handleChange("loadingSignup", false)
      navigate("/")
    } catch (error) {
      handleChange("loadingSignup", false)
      const errorText = Object.values(error?.response?.data)
      if (errorText.length > 0) {
        alert(`Error: ${errorText[0]}`)
      } else {
        alert(`Error: ${error}`)
      }
    }
  }

  return (
    <div>
      <Layout>
        <div className="container minheight80vh">
          <Grid container className='mobileFullWidth' justifyContent={"center"}>
            <Grid item xs={12} sm={10} md={4} className="loginPaper p-4">
              <p className="text_primary text-center font-bold font-18 mb-3">
                Change Password
              </p>
              <AppInput
                className="mb-4 mt-3"
                value={oldpassword}
                type={"password"}
                name={"oldpassword"}
                onChange={handleChange}
                type={isShow ? "text" : "password"}
                label={"Current Password"}
                postfix={
                  <img
                    src={isShow ? eyeIcon : eyeCLose}
                    style={{ objectFit: "contain" }}
                    onClick={() => handleChange("isShow", !isShow)}
                    width={20}
                    className={"c-pointer"}
                  />
                }
                placeholder={"Enter Current Password"}
              />
              <AppInput
                className="mb-4 mt-3"
                value={password_1}
                type={"password"}
                name={"password_1"}
                type={isShow1 ? "text" : "password"}
                onChange={handleChange}
                label={"New password"}
                postfix={
                  <img
                    src={isShow1 ? eyeIcon : eyeCLose}
                    style={{ objectFit: "contain" }}
                    onClick={() => handleChange("isShow1", !isShow1)}
                    width={20}
                    className={"c-pointer"}
                  />
                }
                placeholder={"Enter New Password"}
              />
              <AppInput
                className="mb-4 mt-3"
                value={password_2}
                type={"password"}
                name={"password_2"}
                type={isShow2 ? "text" : "password"}
                onChange={handleChange}
                label={"Confirm Password"}
                postfix={
                  <img
                    src={isShow2 ? eyeIcon : eyeCLose}
                    style={{ objectFit: "contain" }}
                    onClick={() => handleChange("isShow2", !isShow2)}
                    width={20}
                    className={"c-pointer"}
                  />
                }
                placeholder={"Confirm New Password"}
              />
              <AppButton
                title={"Submit"}
                onClick={handleSignup}
                loading={loadingSignup}
                disabled={!oldpassword || !password_1 || !password_2}
                backgroundColor={COLORS.primary}
                color={"#fff"}
              />
            </Grid>
          </Grid>
        </div>
      </Layout>
    </div>
  )
}
