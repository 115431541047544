// @ts-nocheck
/* eslint-disable no-empty-pattern */
import React, { useState, useEffect } from "react"
import { Layout, Loader } from "../../components"
import { Button, Divider, Grid } from "@mui/material"
import AppContext from "../../Context"
import { useContext } from "react"
import { useNavigate } from "react-router-dom"
import { updateOffer } from "../../api/auth"
import { getError } from "../../utils"
import moment from "moment/moment"

export default function Offers({ }) {
  const { allOffers, _listOfOffers, loading } = useContext(AppContext)
  const navigate = useNavigate()
  const [state, setState] = useState({
    loadingStatus: false
  })

  const { loadingStatus } = state

  useEffect(() => {
    _listOfOffers()
  }, [])

  const handleChange = (key, value) => {
    setState(pre => ({
      ...pre,
      [key]: value
    }))
  }

  const handleStatus = async (id, status) => {
    try {
      handleChange("loadingStatus", true)
      const payload = {
        status
      }
      await updateOffer(id, payload)
      _listOfOffers()
      handleChange("loadingStatus", false)
    } catch (error) {
      handleChange("loadingStatus", false)
      getError(error)
    }
  }

  return (
    <div>
      <Layout>
        <div className="container adjustMaxWidth minheight80vh">
          <Grid className="fixWidth">
            <Grid container spacing={2} className="rowBetween tableMobileWidth mb-4 ph-3">
              {loading && <Loader />}
              <Grid item xs={12}>
                <div className="ph-0">
                  <div className="rowBetween">
                    <div className="width25">Offers</div>
                    <div className="width25">Created Date</div>
                    <div className="width25">Accept/Decline Date</div>
                    <div className="width25 text-right">Status</div>
                  </div>
                </div>
              </Grid>
              {!loading &&
                allOffers?.map((item, index) => (
                  <Grid key={index} item xs={12}>
                    <div className="ph-0">
                      <div className="rowBetween">
                        <div className="width25">
                          <Button
                            variant="contained"
                            onClick={() =>
                              item?.pdf_file &&
                              navigate(
                                `/dashboard/offers/pdf?pdf=${item?.pdf_file}`
                              )
                            }
                            style={{
                              textTransform: "capitalize",
                              width: 100,
                              marginRight: 30
                            }}
                          >
                            Offer {index + 1}
                          </Button>
                        </div>
                        <div className="width25">
                          {moment
                            .utc(item?.created_date)
                            .local()
                            .format("MM-DD-YYYY")}
                        </div>
                        <div className="width25">
                          {item?.accept_date
                            ? moment
                              .utc(item?.accept_date)
                              .local()
                              .format("MM-DD-YYYY")
                            : " "}
                        </div>
                        <div className="d-flex width25 justify-content-end align-items-center">
                          {item?.status !== "Decline" && (
                            <Button
                              disabled={
                                loadingStatus || item?.status !== "Awaiting"
                              }
                              onClick={() => handleStatus(item?.id, "Accept")}
                              variant="contained"
                              sx={{
                                ":disabled": { color: "rgba(255,255,255,0.7)" }
                              }}
                              style={{
                                textTransform: "capitalize",
                                backgroundColor: "#00B212",
                                marginRight: item?.status === "Awaiting" ? 30 : 0
                              }}
                            >
                              {item?.status === "Accept" ? "Accepted" : "Accept"}
                            </Button>
                          )}
                          {item?.status !== "Accept" && (
                            <Button
                              sx={{
                                ":disabled": { color: "rgba(255,255,255,0.7)" }
                              }}
                              disabled={
                                loadingStatus || item?.status !== "Awaiting"
                              }
                              onClick={() => handleStatus(item?.id, "Decline")}
                              variant="contained"
                              style={{
                                textTransform: "capitalize",
                                backgroundColor: "#F4512D"
                              }}
                            >
                              {item?.status === "Decline" ? "Denied" : "Deny"}
                            </Button>
                          )}
                        </div>
                        {/* <div className="d-flex align-items-center">
                      {item?.pdf_file && (
                        <a
                          href={item?.pdf_file}
                          target="_blank"
                          download
                        >
                          <span className="mr-2 text_secondary">Download</span>
                          <img src={Cloud_Download} />
                        </a>
                      )}
                    </div> */}
                      </div>
                      <Divider className="mt-4 mb-2" />
                    </div>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </div>
      </Layout>
    </div>
  )
}
