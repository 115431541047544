import React, { useContext, useState } from "react"
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  IconButton,
  Popover,
  Box,
  Modal,
  Grid,
  Button,
  CircularProgress,
  Divider
} from "@mui/material"
import AppInput from "../AppInput"
import { MoreVert } from "@mui/icons-material"
import { commafy, getError } from "../../utils"
import AppContext from "../../Context"
import { updatePortfolio } from "../../api/auth"

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 2,
  p: 2
}
function EnhancedTableHead(props) {
  const { headCells } = props

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={"left"}
            padding={"normal"}
            sortDirection={false}
            sx={{ color: "#000", textTransform: "uppercase", borderBottom: 0 }}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

export default function AppTable({
  rows,
  headCells,
  rowsPage,
  goto,
  onClickItem,
  handleChangeQuantity,
  portfolio,
  state,
  loadingDelete,
  handleDelete,
  pending,
  grandTotal,
  spotPrice,
  totalandquantity,
  retailValue
}) {
  const { _getMyPortfolio } = useContext(AppContext)
  const [anchorEl, setAnchorEl] = useState(null)
  const [selectedCoins, setSelectedCoins] = useState(null)
  const [editableID, setEditableID] = useState(null)
  const [quantity, setQuantity] = useState(0)
  const [openDelete, setOpenDelete] = useState(false)
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(rowsPage || 5)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleClick = (event, row) => {
    setAnchorEl(event.currentTarget)
    setSelectedCoins(row)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleCloseDelete = () => {
    setOpenDelete(false)
    setSelectedCoins(null)
  }

  const handleEdit = async row => {
    try {
      setLoading("loading", true)
      const payload = new FormData()
      payload.append("owned", quantity)
      payload.append("coins", row?.coins?.id)
      await updatePortfolio(row?.id, payload)
      setLoading("loading", false)
      _getMyPortfolio()
      setLoading("isEdit", false)
    } catch (error) {
      setLoading("loading", false)
      getError(error)
    }
  }

  const open = Boolean(anchorEl)
  const id = open ? "simple-popover" : undefined

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows?.length) : 0

  return (
    <TableContainer sx={{ padding: 1 }}>
      <Table
        sx={{ minWidth: 750 }}
        aria-labelledby="tableTitle"
        size={"medium"}
      >
        <EnhancedTableHead headCells={headCells} goto={goto} />
        <TableBody>
          {(rowsPerPage > 0
            ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : rows
          ).map((row, index) => {
            return (
              <TableRow
                hover
                key={index}
                sx={{ height: 50 }}
                className={onClickItem ? "c-pointer" : ""}
                // onClick={() => onClickItem(row?.id)}
              >
                {headCells?.map((item, index1) => {
                  return (
                    <TableCell key={index1} component="th" scope="row">
                      {item?.id === "sell" && (
                        <AppInput
                          placeholder={"0"}
                          value={state[`quantity${index}`]?.quantity || 0}
                          name={`quantity${index}`}
                          onChange={(key, value) =>
                            handleChangeQuantity(key, value, row?.coins?.id)
                          }
                        />
                      )}
                      {item?.id === "lowrange" &&
                        "$" +
                          commafy(
                            (row?.company?.[
                              row?.coins?.metal_type?.metal_type?.toLowerCase() +
                                "_multiplier"
                            ] &&
                            Number(
                              row?.company?.[
                                row?.coins?.metal_type?.metal_type?.toLowerCase() +
                                  "_multiplier"
                              ]
                            ) > 0
                              ? Number(
                                  row?.company?.[
                                    row?.coins?.metal_type?.metal_type?.toLowerCase() +
                                      "_multiplier"
                                  ]
                                )
                              : 1) *
                              (row?.company?.company_multiplier &&
                              Number(row?.company?.company_multiplier) >
                                0
                                ? Number(
                                    row?.company?.company_multiplier
                                  )
                                : 1) *
                              (Number(row?.coins?.metal_type?.price || 0) *
                                Number(row?.coins?.weight || 0) +
                                Number(row?.coins?.bid_premium_low || 0)) *
                              Number(row?.coins?.low_multiplier || 0)
                          )}
                      {item?.id === "highrange" &&
                        "$" +
                          commafy(
                            (row?.company?.[
                              row?.coins?.metal_type?.metal_type?.toLowerCase() +
                                "_multiplier"
                            ] &&
                            Number(
                              row?.company?.[
                                row?.coins?.metal_type?.metal_type?.toLowerCase() +
                                  "_multiplier"
                              ]
                            ) > 0
                              ? Number(
                                  row?.company?.[
                                    row?.coins?.metal_type?.metal_type?.toLowerCase() +
                                      "_multiplier"
                                  ]
                                )
                              : 1) *
                              (row?.company?.company_multiplier &&
                              Number(row?.company?.company_multiplier) >
                                0
                                ? Number(
                                    row?.company?.company_multiplier
                                  )
                                : 1) *
                              (Number(row?.coins?.metal_type?.price || 0) *
                                Number(row?.coins?.weight || 0) +
                                Number(row?.coins?.bid_premium_high || 0)) *
                              Number(row?.coins?.high_multiplier || 0)
                          )}
                      {/* {item?.id === "range" &&
                        "$" +
                          commafy(
                            (Number(
                              row?.coins?.metal_type?.price || 0
                            ) *
                              Number(row?.coins?.weight || 0) +
                              Number(row?.coins?.bid_premium_low || 0)) *
                              Number(row?.coins?.low_multiplier || 0)
                          ) +
                          "-" +
                          ("$" +
                            commafy(
                              (Number(
                                row?.coins?.metal_type?.price || 0
                              ) *
                                Number(row?.coins?.weight || 0) +
                                Number(row?.coins?.bid_premium_high || 0)) *
                                Number(row?.coins?.high_multiplier || 0)
                            ))} */}
                      {item?.id === "total_range" &&
                        "$" +
                          commafy(
                            (row?.company?.[
                              row?.coins?.metal_type?.metal_type?.toLowerCase() +
                                "_multiplier"
                            ] &&
                            Number(
                              row?.company?.[
                                row?.coins?.metal_type?.metal_type?.toLowerCase() +
                                  "_multiplier"
                              ]
                            ) > 0
                              ? Number(
                                  row?.company?.[
                                    row?.coins?.metal_type?.metal_type?.toLowerCase() +
                                      "_multiplier"
                                  ]
                                )
                              : 1) *
                              (row?.company?.company_multiplier &&
                              Number(row?.company?.company_multiplier) >
                                0
                                ? Number(
                                    row?.company?.company_multiplier
                                  )
                                : 1) *
                              (Number(row?.coins?.metal_type?.price || 0) *
                                Number(row?.coins?.weight || 0) +
                                Number(row?.coins?.bid_premium_low || 0)) *
                              Number(row?.coins?.low_multiplier || 0) *
                              row?.owned
                          ) +
                          "-" +
                          ("$" +
                            commafy(
                              (row?.company?.[
                                row?.coins?.metal_type?.metal_type?.toLowerCase() +
                                  "_multiplier"
                              ] &&
                              Number(
                                row?.company?.[
                                  row?.coins?.metal_type?.metal_type?.toLowerCase() +
                                    "_multiplier"
                                ]
                              ) > 0
                                ? Number(
                                    row?.company?.[
                                      row?.coins?.metal_type?.metal_type?.toLowerCase() +
                                        "_multiplier"
                                    ]
                                  )
                                : 1) *
                                (row?.company?.company_multiplier &&
                                Number(
                                  row?.company?.company_multiplier
                                ) > 0
                                  ? Number(
                                      row?.company?.company_multiplier
                                    )
                                  : 1) *
                                (Number(row?.coins?.metal_type?.price || 0) *
                                  Number(row?.coins?.weight || 0) +
                                  Number(row?.coins?.bid_premium_high || 0)) *
                                Number(row?.coins?.high_multiplier || 0) *
                                row?.owned
                            ))}
                      {item?.id === "total" &&
                      row?.coins?.status === "Pending" &&
                      pending
                        ? "Pending"
                        : item?.id === "total" &&
                          "$" +
                            commafy(
                              Number(row?.coins?.metal_type?.price) *
                                Number(row?.coins?.weight) *
                                Number(row?.owned)
                            )}
                      {item?.id === "spot_price" &&
                      row?.coins?.status === "Pending" &&
                      pending
                        ? "Pending"
                        : (item?.id === "price" || item?.id === "spot_price") &&
                          "$" +
                            commafy(
                              Number(row?.coins?.metal_type?.price) *
                                Number(row?.coins?.weight)
                            )}
                      {item?.id === "image" &&
                      row?.coins?.status === "Pending" &&
                      pending
                        ? "Pending"
                        : item?.id === "image" &&
                          portfolio && (
                            <>
                              {row?.coins && row?.coins[item?.id] && (
                                <img
                                  src={row?.coins[item?.id]}
                                  className="coinPic"
                                />
                              )}
                              {row?.coins && row?.coins["name"]}
                            </>
                          )}
                      {item?.id !== "image" &&
                        item?.id !== "total_range" &&
                        portfolio &&
                        row?.coins &&
                        row?.coins[item?.id]}
                      {!portfolio && row[item?.id]}
                      {item?.id === "owned" && editableID === row?.id && (
                        <AppInput
                          placeholder={"Quantity"}
                          value={quantity}
                          onChange={(key, value) => setQuantity(value)}
                        />
                      )}
                      {item?.id === "owned" &&
                        !editableID &&
                        editableID !== row?.id &&
                        row[item?.id]}
                      {item?.id === "action" && (
                        <>
                          <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left"
                            }}
                          >
                            <Typography
                              onClick={() => {
                                setEditableID(selectedCoins?.id)
                                setQuantity(selectedCoins?.owned)
                                setAnchorEl(null)
                              }}
                              sx={{
                                p: 2,
                                cursor: "pointer",
                                bgcolor: "#000",
                                color: "#fff",
                                width: 150
                              }}
                            >
                              Edit
                            </Typography>
                            <Divider />
                            <Typography
                              onClick={() => {
                                setOpenDelete(true)
                                setAnchorEl(null)
                              }}
                              sx={{
                                p: 2,
                                cursor: "pointer",
                                bgcolor: "#000",
                                color: "#fff",
                                width: 150
                              }}
                            >
                              Delete
                            </Typography>
                          </Popover>
                          {editableID === row?.id ? (
                            <Button
                              variant="contained"
                              disabled={!quantity}
                              onClick={event => handleEdit(row)}
                            >
                              Save
                            </Button>
                          ) : (
                            <>
                              {row?.coins?.status === "Pending" && pending ? (
                                ""
                              ) : (
                                <IconButton
                                  onClick={event => handleClick(event, row)}
                                >
                                  {editableID === row?.id ? (
                                    "Edit"
                                  ) : (
                                    <MoreVert />
                                  )}
                                </IconButton>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </TableCell>
                  )
                })}
              </TableRow>
            )
          })}
          {spotPrice && (
            <TableRow sx={{ height: 50 }}>
              <TableCell>{"Total"}</TableCell>
              <TableCell>{""}</TableCell>
              <TableCell>{totalandquantity()?.quantity}</TableCell>
              <TableCell>${totalandquantity()?.total}</TableCell>
              <TableCell>{""}</TableCell>
            </TableRow>
          )}
          {retailValue && (
            <TableRow sx={{ height: 50 }}>
              <TableCell>{"Total"}</TableCell>
              <TableCell>{""}</TableCell>
              <TableCell>{""}</TableCell>
              <TableCell>{totalandquantity()?.quantity}</TableCell>
              <TableCell>${totalandquantity()?.total}</TableCell>
            </TableRow>
          )}
          {grandTotal && (
            <TableRow sx={{ height: 50 }}>
              <TableCell>{""}</TableCell>
              <TableCell>{""}</TableCell>
              <TableCell className="font-bold">{"Grand Total"}</TableCell>
              <TableCell className="font-bold">${grandTotal}</TableCell>
              <TableCell>{""}</TableCell>
            </TableRow>
          )}
          {emptyRows > 0 && (
            <TableRow
              style={{
                height: 53 * emptyRows
              }}
            >
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
      </Table>
      <Modal
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="font-24 text-center font-bold">
            Are you sure you want to delete this coin?
          </div>
          <div>
            {selectedCoins?.coins?.image && (
              <img src={selectedCoins?.coins?.image} className="coinPic" />
            )}
            {selectedCoins?.coins?.name}
          </div>
          <Grid container spacing={1} className="mt-4 mb-4">
            <Button
              onClick={() => handleDelete(selectedCoins?.id)}
              sx={{ color: "#000", width: "50%", fontSize: 18 }}
            >
              {loadingDelete ? (
                <CircularProgress
                  style={{ width: 20, height: 20, color: "#000" }}
                />
              ) : (
                "Yes"
              )}
            </Button>
            <Button
              onClick={handleCloseDelete}
              sx={{ color: "#000", width: "50%", fontSize: 18 }}
            >
              Cancel
            </Button>
          </Grid>
        </Box>
      </Modal>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        className="paginationMobileWidth"
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  )
}
