// @ts-nocheck
/* eslint-disable no-empty-pattern */
import React, { useEffect, useState } from "react"
import {
  AppButton,
  AppInput,
  HomeHeader,
  Label,
  MainFooter
} from "../../components"
import { Grid, Typography, Checkbox } from "@mui/material"
import eyeIcon from "../../assets/svg/eye.svg"
import { Link, useNavigate } from "react-router-dom"
import { signupUser } from "../../api/auth"
import AppContext from "../../Context"
import { useContext } from "react"
import { COLORS } from "../../constants"
import { useSnackbar } from "notistack"
import eyeCLose from "../../assets/images/closeEYE.jpg"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/material.css"

export default function Signup({}) {
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const { user, setUser } = useContext(AppContext)
  const path = window.location.pathname
  const [state, setState] = useState({
    activeTab: 0,
    email: "",
    password: "",
    phone_number: "",
    loading: false,
    loadingSignup: false,
    checked: false,
    isShow: false
  })

  useEffect(() => {
    if (path) {
      handleChange("activeTab", path === "/signup" ? 0 : 1)
    }
    if (user) {
      navigate("/dashboard/profile")
    }
  }, [path, user])

  const {
    first_name,
    last_name,
    email,
    password,
    loading,
    emailSignup,
    phone_number,
    checked,
    isShow
  } = state

  const handleChange = (key, value) => {
    setState(pre => ({
      ...pre,
      [key]: value
    }))
  }

  const handleSignup = async () => {
    try {
      handleChange("loading", true)
      const payload = {
        first_name,
        last_name,
        email,
        password,
        phone_number
      }
      const res = await signupUser(payload)
      localStorage.setItem("email", email)
      handleChange("loading", false)
      enqueueSnackbar(`Signup Successfully`, {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
      navigate(`/verfication`)
    } catch (error) {
      handleChange("loading", false)
      const errorText = Object.values(error?.response?.data)
      if (errorText.length > 0) {
        alert(`Error: ${errorText[0]}`)
      } else {
        alert(`Error: ${error}`)
      }
    }
  }

  return (
    <div>
      <HomeHeader />
      <section className="bg-white">
        <div className="container divCenter loginContainer">
          <Grid container className='mobileFullWidth' justifyContent={"center"}>
            <Grid item xs={12} sm={10} md={6} lg={4} className="loginPaper mtm-3">
              <div className="text_primary text-center font-18 font-bold">
                Sign Up
              </div>
              <AppInput
                label={"First Name"}
                value={first_name}
                name={"first_name"}
                onChange={handleChange}
                className="mb-3 mt-3"
                placeholder={"Enter First Name"}
              />
              <AppInput
                label={"Last Name"}
                value={last_name}
                name={"last_name"}
                onChange={handleChange}
                className="mb-3 mt-3"
                placeholder={"Enter Last Name"}
              />
              <Label text={"Phone"} />
              <PhoneInput
                label={""}
                country={"us"}
                inputStyle={{ height: 55, width: "100%" }}
                containerStyle={{ height: 55 }}
                value={phone_number}
                name={"phone_number"}
                onChange={phone => handleChange("phone_number", phone)}
                className="mb-3 mt-3"
                placeholder={"Enter Phone"}
              />
              <AppInput
                label={"Email"}
                value={email}
                name={"email"}
                onChange={handleChange}
                className="mb-3 mt-3"
                placeholder={"Enter Email"}
              />
              <AppInput
                className="mb-4 mt-3"
                label={"Password"}
                value={password}
                type={isShow ? "text" : "password"}
                name={"password"}
                onChange={handleChange}
                postfix={
                  <img
                    src={isShow ? eyeIcon : eyeCLose}
                    style={{ objectFit: "contain" }}
                    onClick={() => handleChange("isShow", !isShow)}
                    width={20}
                    className={"c-pointer"}
                  />
                }
                placeholder={"********"}
              />
              <div className="rowBetween mb-4">
                <div className="checkboxDiv mt-1">
                  <input
                    type={"checkbox"}
                    checked={checked}
                    onClick={() => handleChange("checked", !checked)}
                    className="checkbox1"
                  />
                  <Typography
                    variant="body2"
                    className="text_primary06 checkboxLabel"
                  >
                    I have read{" "}
                    <span
                      onClick={() => navigate("/terms-conditions")}
                      className="hyperlink"
                    >
                      Terms and Conditions
                    </span>{" "}
                    and{" "}
                    <span
                      onClick={() => navigate("/privacy-policy")}
                      className="hyperlink"
                    >
                      Privacy Policy
                    </span>
                  </Typography>
                </div>
              </div>
              <AppButton
                title={"Sign Up"}
                onClick={handleSignup}
                loading={loading}
                disabled={
                  !email ||
                  !password ||
                  !first_name ||
                  !last_name ||
                  !checked ||
                  !phone_number
                }
                backgroundColor={COLORS.primary}
                color={"#fff"}
              />
              <div className="mt-4 text-center">
                <Link
                  to={"/login"}
                  className="text-center font-14  text_secondary c-pointer"
                >
                  Already A Member? Sign In
                </Link>
              </div>
            </Grid>
          </Grid>
        </div>
      </section>
      <div style={{ height: 200 }} />
      <MainFooter />
    </div>
  )
}
