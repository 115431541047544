import React from "react"
import { Divider, Grid } from "@mui/material"
import { Link } from "react-router-dom"

export default function MainFooter({ loggedIn }) {
  let date = new Date().getFullYear()
  return (
    <div>
      {/* <!--Main footer--> */}
      <footer className={loggedIn?"common_footer_1": "common_footer"}>
        <div className="container">
          <Grid container justifyContent={"space-between"}>
            <div
              style={{ fontSize: 14, color: "#212121" }}
              className="mb-4 mt-4 text-center"
            >
              © Copyright Metals IRA {new Date().getFullYear()}. All Rights
              Reserved
            </div>
            <div className="mb-4 mt-4 d-flex text-center">
              <div className="mr-5 c-pointer">
                <Link
                  style={{
                    fontWeight: loggedIn ? "bold" : "normal",
                    fontSize: 14,
                    color: loggedIn ? "#2D6FF4" : "#3D3D3D"
                  }}
                  to={"/terms-conditions"}
                >
                  Terms & Conditions
                </Link>
              </div>
              <div className="c-pointer">
                <Link
                  style={{
                    fontWeight: loggedIn ? "bold" : "normal",
                    fontSize: 14,
                    color: loggedIn ? "#2D6FF4" : "#3D3D3D"
                  }}
                  to={"/privacy-policy"}
                >
                  Privacy Policy
                </Link>
              </div>
            </div>
          </Grid>
        </div>
      </footer>
    </div>
  )
}
