// @ts-nocheck
/* eslint-disable no-empty-pattern */
import React, { useState } from "react"
import { Layout, Loader } from "../../components"
import { Grid, Divider, Button, CircularProgress } from "@mui/material"
import { useLocation, useNavigate } from "react-router-dom"
import AppContext from "../../Context"
import { useContext } from "react"
import AppTable from "../../components/AppTable"
import { COLORS } from "../../constants"
import { useSnackbar } from "notistack"
import { createOffer } from "../../api/auth"
import { useEffect } from "react"
import { commafy } from "../../utils"

const headCells1 = [
  {
    id: "image",
    numeric: false,
    disablePadding: true,
    sx: { width: 400 },
    label: "Name"
  },
  {
    id: "spot_price",
    numeric: false,
    disablePadding: false,
    label: "Current Price"
  },
  {
    id: "owned",
    numeric: false,
    disablePadding: false,
    label: "Quantity"
  },
  {
    id: "total",
    numeric: false,
    disablePadding: false,
    label: "Total"
  }
]

const headCells2 = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    sx: { width: 400 },
    label: "Name"
  },
  // {
  //   id: "price",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Current retail price range/ounce"
  // },
  {
    id: "lowrange",
    numeric: false,
    disablePadding: false,
    label: "Current Retail Price Low"
  },
  {
    id: "highrange",
    numeric: false,
    disablePadding: false,
    label: "Current Retail Price High"
  },
  {
    id: "owned",
    numeric: false,
    disablePadding: false,
    label: "Quantity"
  },
  {
    id: "total_range",
    numeric: false,
    disablePadding: false,
    label: "Total range"
  }
]

const headCells3 = [
  {
    id: "image",
    numeric: false,
    disablePadding: true,
    sx: { width: 400 },
    label: "Name "
  },
  {
    id: "spot_price",
    numeric: false,
    disablePadding: false,
    label: "Current target price"
  },
  {
    id: "owned",
    numeric: false,
    disablePadding: false,
    label: "Owned"
  },
  {
    id: "sell",
    numeric: false,
    disablePadding: false,
    label: "Quantity to Sell"
  }
]

export default function Home({}) {
  const navigate = useNavigate()
  const {
    allPortfolio,
    loading: mainLoading,
    advertisement,
    _getAllPortfolio,
    _getAdvertisement
  } = useContext(AppContext)
  const { enqueueSnackbar } = useSnackbar()
  const location = useLocation()
  const [state, setState] = useState({
    loading: false,
    categories: "",
    searchText: "",
    coins: allPortfolio || []
  })

  const { loading, coins } = state

  useEffect(() => {
    if (allPortfolio) {
      handleChange("coins", allPortfolio)
    }
  }, [allPortfolio])

  useEffect(() => {
    _getAllPortfolio()
    _getAdvertisement()
  }, [])

  console.log("state", state)
  const handleChange = (key, value) => {
    setState(pre => ({
      ...pre,
      [key]: value
    }))
  }
  const handleChangeQuantity = (key, value, id) => {
    handleChange(key, { quantity: value, coin_id: id })
    const newList = []
    coins.forEach(element => {
      if (element?.coins?.id === id) {
        newList.push({ ...element, coin_id: id, quantity: value })
      } else {
        newList.push({ ...element })
      }
    })
    console.log("element", newList)
    handleChange("coins", newList)
  }

  const handleSubmit = async () => {
    try {
      handleChange("loading", true)
      const selectedCoins = []
      coins?.forEach(element => {
        if (element?.coin_id) {
          selectedCoins.push({
            coin_id: element?.coin_id,
            quantity: element?.quantity
          })
        }
      })
      const payload = {
        coins: selectedCoins
      }
      await createOffer(payload)
      allPortfolio?.forEach((element, index) => {
        handleChange(`quantity${index}`, null)
      })
      handleChange("loading", false)
      handleChange("coins", allPortfolio)
      enqueueSnackbar(`Offer has been submitted`, {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    } catch (error) {
      handleChange("loading", false)
      const errorText = Object.values(error?.response?.data)
      enqueueSnackbar(`Error: ${errorText[0]}`, {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }

  const totalandquantity = () => {
    let total = 0
    let total1 = 0
    let quantity = 0
    if (location.pathname === "/dashboard/retail-value") {
      allPortfolio?.forEach(element => {
        quantity = element?.owned + quantity
        total =
          (element?.company?.[
            element?.coins?.metal_type?.metal_type?.toLowerCase() +
              "_multiplier"
          ] &&
          Number(
            element?.company?.[
              element?.coins?.metal_type?.metal_type?.toLowerCase() +
                "_multiplier"
            ]
          ) > 0
            ? Number(
                element?.company?.[
                  element?.coins?.metal_type?.metal_type?.toLowerCase() +
                    "_multiplier"
                ]
              )
            : 1) *
            (element?.company?.company_multiplier &&
            Number(element?.company?.company_multiplier) > 0
              ? Number(element?.company?.company_multiplier)
              : 1) *
            ((Number(element?.coins?.metal_type?.price || 0) *
              Number(element?.coins?.weight || 0) +
              Number(element?.coins?.bid_premium_low || 0)) *
              Number(element?.coins?.low_multiplier || 0) *
              element?.owned) +
          total
        total1 =
          (element?.company?.[
            element?.coins?.metal_type?.metal_type?.toLowerCase() +
              "_multiplier"
          ] &&
          Number(
            element?.company?.[
              element?.coins?.metal_type?.metal_type?.toLowerCase() +
                "_multiplier"
            ]
          ) > 0
            ? Number(
                element?.company?.[
                  element?.coins?.metal_type?.metal_type?.toLowerCase() +
                    "_multiplier"
                ]
              )
            : 1) *
            (element?.company?.company_multiplier &&
            Number(element?.company?.company_multiplier) > 0
              ? Number(element?.company?.company_multiplier)
              : 1) *
            ((Number(element?.coins?.metal_type?.price || 0) *
              Number(element?.coins?.weight || 0) +
              Number(element?.coins?.bid_premium_high || 0)) *
              Number(element?.coins?.high_multiplier || 0)) *
            element?.owned +
          total1
      })
      return {
        total: commafy(total) + "-" + commafy(total1),
        quantity: commafy(quantity)
      }
    } else {
      allPortfolio?.forEach(element => {
        quantity = element?.owned + quantity
        total =
          Number(element?.coins?.metal_type?.price) *
            Number(element?.coins?.weight) *
            Number(element?.owned) +
          total
      })
      return { total: commafy(total), quantity: commafy(quantity) }
    }
  }

  return (
    <div>
      <Layout>
        <div className="container adjustMaxWidth minheight80vh">
          <Grid container justifyContent={"center"}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className="p-4"
              style={{ marginTop: -30 }}
            >
              <Grid
                container
                justifyContent={"center"}
                className={"mb-3"}
                alignItems={"center"}
              >
                <div
                  className="blueBorderBox"
                  style={{
                    borderRadius: 30,
                    border: `1px solid ${COLORS.primary}`,
                    padding: 5
                  }}
                >
                  <Button
                    variant="contained"
                    onClick={() => navigate("/dashboard/spot-price")}
                    sx={{
                      borderRadius: 30,
                      backgroundColor: COLORS.primary,
                      textTransform: "capitalize",
                      width: 200
                    }}
                  >
                    1. Spot Price
                  </Button>
                  <Button
                    variant="contained"
                    className="marginMobileV"
                    onClick={() => navigate("/dashboard/retail-value")}
                    sx={{
                      borderRadius: 30,
                      backgroundColor: COLORS.retail,
                      ml: 1,
                      mr: 1,
                      textTransform: "capitalize",
                      width: 200
                    }}
                  >
                    2. Retail Value
                  </Button>

                  <Button
                    variant="contained"
                    onClick={() => navigate("/dashboard/makeoffers")}
                    sx={{
                      borderRadius: 30,
                      backgroundColor: COLORS.offer,
                      textTransform: "capitalize",
                      width: 200
                    }}
                  >
                    3. Make Me An Offer
                  </Button>
                </div>
              </Grid>
              <Divider />
              {mainLoading ? (
                <Loader />
              ) : (
                <Grid container spacing={2} className={"mt-3"}>
                  <AppTable
                    onClickItem={() => navigate("/my-booking/details")}
                    headCells={
                      location.pathname === "/dashboard/spot-price"
                        ? headCells1
                        : location.pathname === "/dashboard/retail-value"
                        ? headCells2
                        : headCells3
                    }
                    handleChangeQuantity={handleChangeQuantity}
                    state={state}
                    rows={allPortfolio}
                    totalandquantity={totalandquantity}
                    spotPrice={location.pathname === "/dashboard/spot-price"}
                    retailValue={
                      location.pathname === "/dashboard/retail-value"
                    }
                    portfolio
                    rowsPage={15}
                  />
                </Grid>
              )}
              {location.pathname === "/dashboard/makeoffers" && (
                <Grid container justifyContent={"flex-end"}>
                  <Button
                    variant="contained"
                    disabled={!coins.some(e => Number(e?.quantity) > 0)}
                    onClick={handleSubmit}
                  >
                    {loading ? (
                      <CircularProgress
                        style={{ width: 20, height: 20, color: "#fff" }}
                      />
                    ) : (
                      "Submit"
                    )}
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </div>
        <Grid container justifyContent={"center"}>
          {advertisement?.map((item, index) => (
            <div key={index}>{item?.text}</div>
          ))}
        </Grid>
      </Layout>
    </div>
  )
}
