import axios from "axios"
import { API } from "./"
import { API_URL } from "./config"

export const signupUser = payload => {
  return API.post("api/v1/signup/", payload)
}

export const loginUser = payload => {
  return API.post("api/v1/login/", payload)
}

export const facebookLoginUser = payload => {
  return API.post("users/facebook/login/", payload)
}

export const appleLoginUser = payload => {
  return API.post("users/apple/login/", payload)
}

export const googleLoginUser = payload => {
  return API.post("users/google/login/", payload)
}

export const verifyEmail = payload => {
  return API.post("users/email/verify-user/", payload)
}

export const resendOTP = payload => {
  return API.post("users/email/resend-token/", payload)
}

export const setPassword = (payload, token) => {
  return API.post("api/v1/users/password/", payload, token)
}

export const changePassword1 = payload => {
  return API.post("users/password/change/", payload)
}

export const createProfile = (payload, id, token) => {
  return API.post(`api/v1/profile/`, payload, token)
}

export const updateProfile = (id, payload, token) => {
  return API.put(`users/profile/${id}/`, payload, token)
}

export const editProfile = (payload, token) => {
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      Authorization: `Token ${token}`
    }
  }
  return API.patch(`api/v1/client/edit/`, payload, token)
  // return axios.patch(`${API_URL()}api/v1/client/edit/`, payload, options)
}

export const deleteAccount = (user_id, token) => {
  return API.delete(`api/v1/users/${user_id}/`, {}, token)
}

export const getNotification = () => {
  return API.get(`api/v1/user-notification/`)
}

export const forgotpasswordCode = payload => {
  return API.post("api/v1/forgotpasswordcode", payload)
}

export const forgotpassword = payload => {
  return API.post("rest-auth/password/reset/", payload)
}

export const addAddress = (payload, token) => {
  return API.post("api/v1/address/", payload, token)
}

export const changePassword = (payload, token) => {
  return API.post("api/v1/users/password/", payload, token)
}

export const getProfile = () => {
  return API.get(`users/profile/`)
}

export const createPortfolio = payload => {
  return API.post("api/v1/portfolio/", payload)
}

export const getMyPortfolio = () => {
  return API.get("api/v1/portfolio/")
}

export const getAllPortfolio = () => {
  return API.get("api/v1/portfolio/")
}

export const getAdvertisement = () => {
  return API.get("api/v1/advertisement/")
}

export const getCoins = () => {
  return API.get("api/v1/coins/")
}

export const getCompany = () => {
  return API.get("api/v1/company/")
}

export const createFeedback = payload => {
  return API.post("api/v1/feedback/", payload)
}

export const createOffer = payload => {
  return API.post("api/v1/create-offer-list/", payload)
}

export const listOfOffers = () => {
  return API.get("api/v1/offers/")
}

export const inviteFriend = (payload, token) => {
  return API.post("api/v1/users/invite/", payload, token)
}
export const addCoin = payload => {
  return API.post("api/v1/coins/", payload)
}

export const getCoinByID = id => {
  return API.get(`api/v1/coins/${id}/`)
}

export const getMetalTypes = () => {
  return API.get(`api/v1/metal-type/`)
}

export const uploadOffers = payload => {
  return API.post("api/v1/invoice/", payload)
}

export const updateOffer = (id, payload) => {
  return API.put(`api/v1/offers/${id}/`, payload)
}

export const deletePortfolio = id => {
  return API.delete(`api/v1/portfolio/${id}/`, {})
}

export const updatePortfolio = (id, data) => {
  return API.put(`api/v1/portfolio/${id}/`, data)
}
