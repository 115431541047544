// @ts-nocheck
/* eslint-disable no-empty-pattern */
import React, { useEffect, useState } from "react"
import { AppButton, AppInput, HomeHeader, MainFooter } from "../../components"
import { Grid, Typography } from "@mui/material"
import eyeIcon from "../../assets/svg/eye.svg"
import eyeCLose from "../../assets/images/closeEYE.jpg"
import { Link, useNavigate } from "react-router-dom"
import { loginUser } from "../../api/auth"
import AppContext from "../../Context"
import { useContext } from "react"
import { COLORS } from "../../constants"
import { useSnackbar } from "notistack"

export default function Login({}) {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const { user, setUser, _getProfile } = useContext(AppContext)
  const path = window.location.pathname
  const [state, setState] = useState({
    activeTab: 0,
    email: "",
    password: "",
    emailSignup: "",
    passwordSignup: "",
    loading: false,
    loadingSignup: false,
    checked: false,
    isShow: false
  })

  useEffect(() => {
    if (path) {
      handleChange("activeTab", path === "/signup" ? 0 : 1)
    }
    if (user) {
      navigate("/dashboard/profile")
    }
  }, [path, user])

  const { email, password, loading, checked, isShow } = state

  const handleChange = (key, value) => {
    setState(pre => ({
      ...pre,
      [key]: value
    }))
  }

  const handleLogin = async () => {
    try {
      handleChange("loading", true)
      const payload = {
        username: email,
        password
      }
      const res = await loginUser(payload)
      localStorage.setItem("token", res?.data?.token)
      localStorage.setItem("user", JSON.stringify(res?.data?.user))
      handleChange("loading", false)
      _getProfile()
      setUser(res?.data?.user)
      enqueueSnackbar(`Login Successfully`, {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
      navigate("/dashboard/spot-price")
    } catch (error) {
      handleChange("loading", false)
      const errorText = Object.values(error?.response?.data)
      if (errorText.length > 0) {
        alert(`Error: ${errorText[0]}`)
      } else {
        alert(`Error: ${error}`)
      }
    }
  }

  return (
    <div>
      <HomeHeader />
      <section className="bg-white">
        <div className="container divCenter loginContainer">
          <Grid container className='mobileFullWidth' justifyContent={"center"}>
            <Grid item xs={12} sm={10} md={6} lg={4} className="loginPaper mtm-3">
              <div className="text_primary text-center font-18 font-bold">
                Sign In
              </div>
              <AppInput
                label={"Username"}
                value={email}
                name={"email"}
                onChange={handleChange}
                className="mb-3 mt-3"
                placeholder={"Enter Username"}
              />
              <AppInput
                className="mb-4 mt-3"
                label={"Password"}
                value={password}
                type={isShow ? "text" : "password"}
                name={"password"}
                onChange={handleChange}
                postfix={
                  <img
                    src={isShow ? eyeIcon : eyeCLose}
                    style={{ objectFit: "contain" }}
                    onClick={() => handleChange("isShow", !isShow)}
                    width={20}
                    className={"c-pointer"}
                  />
                }
                placeholder={"********"}
              />
              <div className="rowBetween mb-4">
                <div className="checkboxDiv mt-1">
                  <input
                    type={"checkbox"}
                    checked={checked}
                    onClick={() => handleChange("checked", !checked)}
                    className="checkbox1"
                    size={"large"}
                    // sx={{
                    //   color: "rgba(201, 208, 216, 1)",
                    //   "&.Mui-checked": {
                    //     color: "rgba(168, 124, 81, 1)"
                    //   }
                    // }}
                  />
                  <Typography
                    variant="body2"
                    className="text_primary06 checkboxLabel"
                  >
                    Remember Me
                  </Typography>
                </div>
                <div
                  className="c-pointer"
                  onClick={() => navigate("/forgot-password")}
                >
                  <p className="text_secondary">Forgot Password?</p>
                </div>
              </div>
              <AppButton
                title={"Sign In"}
                onClick={handleLogin}
                loading={loading}
                disabled={!email || !password}
                backgroundColor={COLORS.primary}
                color={"#fff"}
              />
              <div className="mt-4 text-center">
                <Link
                  to={"/signup"}
                  className="text-center font-14  text_secondary c-pointer"
                >
                  New Member? Sign Up
                </Link>
              </div>
            </Grid>
          </Grid>
        </div>
      </section>
      <div style={{ height: 200 }} />
      <MainFooter />
    </div>
  )
}
