// @ts-nocheck
/* eslint-disable no-empty-pattern */
import React, { useRef, useState } from "react"
import { AppButton, AppInput, Label, Layout } from "../../components"
import {
  Grid,
  Divider,
  Button,
  Typography,
  Autocomplete,
  TextField
} from "@mui/material"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import AppContext from "../../Context"
import { useContext } from "react"
import { COLORS } from "../../constants"
import saerchIcon from "../../assets/svg/saerchIcon.svg"
import callIcon from "../../assets/svg/call.svg"
import { addCoin, createPortfolio, getCoinByID } from "../../api/auth"
import { useSnackbar } from "notistack"
import { FileUploader } from "react-drag-drop-files"
import { useEffect } from "react"

const fileTypes = ["JPEG", "JPG", "PNG"]

export default function AddCoinManually({}) {
  const navigate = useNavigate()
  const autocompleteRef = useRef()
  const { enqueueSnackbar } = useSnackbar()
  let [searchParams, setSearchParams] = useSearchParams()
  const id = searchParams.get("id")
  const location = useLocation()
  const { metalTypes, _getCoins, companies, _getCompany, coins } =
    useContext(AppContext)
  const [state, setState] = useState({
    loading: false,
    name: "",
    weight: "",
    quantity: "",
    metal_type: "",
    company: "",
    bid_premium_percent_low: "",
    bid_premium_percent_high: "",
    bid_premium_low: "",
    bid_premium_high: "",
    low_multiplier: "",
    high_multiplier: "",
    pictureURL: "",
    picture: "",
    coinID: ""
  })

  const {
    loading,
    name,
    weight,
    quantity,
    metal_type,
    company,
    bid_premium_percent_low,
    bid_premium_percent_high,
    bid_premium_low,
    bid_premium_high,
    low_multiplier,
    high_multiplier,
    pictureURL,
    picture,
    coinID
  } = state

  useEffect(() => {
    _getCompany()
  }, [])

  useEffect(() => {
    _getCoins()
    if (id) {
      _getCoinByID()
    }
  }, [id])

  const handleChangeImage = file => {
    if (file) {
      handleChange("avatar", file)
      const reader = new FileReader()
      reader.readAsDataURL(file)
      handleChange("picture", file)
      reader.onloadend = function (e) {
        handleChange("pictureURL", reader.result)
      }
    }
  }

  console.log("companies", companies)

  const handleChange = (key, value) => {
    setState(pre => ({
      ...pre,
      [key]: value
    }))
  }
  const handleSubmit = async () => {
    try {
      handleChange("loading", true)
      const formData = new FormData()
      if (coinID) {
        formData.append("coins", coinID)
        formData.append("company", company)
        formData.append("owned", quantity)
        await createPortfolio(formData)
      } else {
        const formData1 = new FormData()
        formData1.append("name", name)
        formData1.append("weight", weight)
        formData1.append("metal_type", metal_type)
        formData1.append("is_accepted", false)
        formData1.append("company", company)
        const coin = await addCoin(formData1)
        formData.append("coins", coin?.data?.id)
        formData.append("company", company)
        formData.append("owned", quantity)
        await createPortfolio(formData)
      }
      // bid_premium_percent_low &&
      //   formData.append('bid_premium_percent_low', bid_premium_percent_low)
      // bid_premium_percent_high &&
      //   formData.append('bid_premium_percent_high', bid_premium_percent_high)
      // bid_premium_low && formData.append('bid_premium_low', bid_premium_low)
      // bid_premium_high && formData.append('bid_premium_high', bid_premium_high)
      // low_multiplier && formData.append('low_multiplier', low_multiplier)
      // high_multiplier && formData.append('high_multiplier', high_multiplier)
      enqueueSnackbar(`Portfolio has been created`, {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
      handleChange("loading", false)
      _getCoins()
      navigate("/dashboard/portfolio")
    } catch (error) {
      handleChange("loading", false)
      const errorText = Object.values(error?.response?.data)
      enqueueSnackbar(`Error: ${errorText[0]}`, {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }

  const getByLabelText = id => {
    const a_id = id?.split("id")
    // const found = coins?.find(e => e.id === Number(id))
    return (a_id?.length > 0 && a_id[0]) || ""
  }

  const _getCoinByID = async (id, blur) => {
    try {
      const a_id = id?.split("id")
      if (a_id?.length > 0) {
        const res = await getCoinByID(a_id[1])
        console.log("res", res?.data)
        handleChange("coinID", res?.data?.id)
        handleChange("name", res?.data?.name)
        handleChange("metal_type", res?.data?.metal_type?.id)
        handleChange("company", res?.data?.company?.id)
        handleChange("weight", res?.data?.weight)
        if (blur) {
          autocompleteRef?.current?.blur()
        }
      }
    } catch (error) {
      handleChange("loading", false)
      const errorText = Object.values(error?.response?.data)
      enqueueSnackbar(`Error: ${errorText[0]}`, {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }

  return (
    <div>
      <Layout>
        <div className="container divCenter">
          <Grid container justifyContent={"center"}>
            <Grid item xs={10} sm={10} md={6} lg={5} className="loginPaper">
              <div className="avatarDiv font-bold font-24 mb-3">
                Build Portfolio
              </div>
              {/* <div className='avatarDiv mb-3'>
                {pictureURL && <img src={pictureURL} className={'avatar'} />}
                <FileUploader
                  multiple={false}
                  handleChange={handleChangeImage}
                  onDrag={handleChangeImage}
                  name='file1'
                  types={fileTypes}
                  className={'dragndropDiv1'}
                  children={
                    <AppButton
                      title={'Add Image*'}
                      backgroundColor={COLORS.white}
                      color={COLORS.primary}
                      borderRadius={50}
                      borderColor={COLORS.primary}
                      width={120}
                      height={40}
                      className={'mt-4'}
                    />
                  }
                />
              </div> */}
              <Label text={"Name of Coin"} />
              <Autocomplete
                variant={"filled"}
                backgroundColor={"#fff"}
                postfix={
                  <img src={saerchIcon} width={20} className={"ml-2 mr-2"} />
                }
                disablePortal
                id="combo-box-demo"
                options={coins}
                renderOption={props => (
                  <div
                    style={{
                      cursor: "pointer",
                      height: 50,
                      paddingLeft: 15,
                      paddingTop: 5
                    }}
                    onClick={() => {
                      _getCoinByID(props?.key, true)
                    }}
                  >
                    {getByLabelText(props?.key)}
                    {/* {props?.key} */}
                  </div>
                )}
                // onChange
                // disableClearable
                getOptionLabel={option => `${option?.name} id${option?.id}`}
                autoComplete
                freeSolo
                // disableCloseOnSelect
                disableClearable
                // ref={autocompleteRef}
                // value={name}
                inputValue={name}
                // onChange={(event, newValue) => {
                //   console.log("newValue", event, newValue)
                //   handleChange("name", newValue)
                // }}
                onInputChange={(event, newInputValue) => {
                  handleChange("name", event?.target?.value)
                }}
                // sx={{ width: 300 }}
                renderInput={params => (
                  <TextField
                    {...params}
                    inputRef={autocompleteRef}
                    label="Enter Name of Coin"
                  />
                )}
                placeholder={"Enter Name of Coin"}
              />
              <AppInput
                label={"Weight of Coin (oz)"}
                value={weight}
                name={"weight"}
                type="number"
                onChange={handleChange}
                className="mb-3 mt-3"
                placeholder={"Enter Weight of Coin (Ounces)"}
              />

              <AppInput
                label={"Type of Coin"}
                value={metal_type}
                name={"metal_type"}
                select
                selectOptions={
                  <>
                    <option value={""}>Select Metal Type</option>
                    {metalTypes?.map((metal, index) => (
                      <option key={index} value={metal?.id}>
                        {metal?.metal_type}
                      </option>
                    ))}
                  </>
                }
                onChange={handleChange}
                className="mb-3 mt-3"
                placeholder={"Confirm Type of Coin"}
              />
              <AppInput
                label={"Quantity"}
                type="number"
                value={quantity}
                name={"quantity"}
                onChange={handleChange}
                className="mb-3 mt-3"
                placeholder={"Confirm Quantity"}
              />
              <AppInput
                label={"Where did you purchase coins from?"}
                value={company}
                select
                selectOptions={
                  <>
                    <option value={""}>Select Company</option>
                    {companies?.map((company, index) => (
                      <option key={index} value={company?.id}>
                        {company?.name}
                      </option>
                    ))}
                  </>
                }
                name={"company"}
                onChange={handleChange}
                className="mb-3 mt-3"
                placeholder={"Optional"}
              />
              {/* <AppInput
                label={'Bid premium percent low'}
                value={bid_premium_percent_low}
                name={'bid_premium_percent_low'}
                onChange={handleChange}
                className='mb-3 mt-3'
                placeholder={'Optional'}
              />
              <AppInput
                label={'Bid premium percent high'}
                value={bid_premium_percent_high}
                name={'bid_premium_percent_high'}
                onChange={handleChange}
                className='mb-3 mt-3'
                placeholder={'Optional'}
              />
              <AppInput
                label={'Bid premium low'}
                value={bid_premium_low}
                name={'bid_premium_low'}
                onChange={handleChange}
                className='mb-3 mt-3'
                placeholder={'Optional'}
              />
              <AppInput
                label={'Bid premium high'}
                value={bid_premium_high}
                name={'bid_premium_high'}
                onChange={handleChange}
                className='mb-3 mt-3'
                placeholder={'Optional'}
              />
              <AppInput
                label={'Low multiplier'}
                value={low_multiplier}
                name={'low_multiplier'}
                onChange={handleChange}
                className='mb-3 mt-3'
                placeholder={'Optional'}
              />
              <AppInput
                label={'High multiplier'}
                value={high_multiplier}
                name={'high_multiplier'}
                onChange={handleChange}
                className='mb-3 mt-3'
                placeholder={'Optional'}
              /> */}

              <AppButton
                title={"Confirm"}
                onClick={handleSubmit}
                loading={loading}
                disabled={
                  !name || !weight || !metal_type || !quantity || !company
                }
                backgroundColor={COLORS.primary}
                color={"#fff"}
              />
            </Grid>
          </Grid>
        </div>
      </Layout>
    </div>
  )
}
