// @ts-nocheck
/* eslint-disable no-empty-pattern */
import React, { useState } from "react"
import { Layout } from "../../components"
import { Grid } from "@mui/material"
import AppContext from "../../Context"
import { useContext } from "react"
import { useSearchParams } from "react-router-dom"

export default function Offers({}) {
  let [searchParams] = useSearchParams()
  const pdf = searchParams.get("pdf")
  const getPDF = url => {
    const a = url?.split("pdf?")
    if (Array.isArray(a) && a?.length > 0) {
      return a[0] + "pdf"
    } else return url
  }
  return (
    <div>
      <Layout>
        <div className="container adjustMaxWidth minheight80vh">
          <Grid container spacing={2} className="rowBetween mt-3 mb-4 ph-3">
            {pdf && (
              <object
                className="myframe"
                data={getPDF(pdf)}
                type="application/pdf"
              >
                <iframe
                  className="myframe"
                  src={`https://docs.google.com/viewer?url=${getPDF(
                    pdf
                  )}&embedded=true`}
                ></iframe>
              </object>
            )}
          </Grid>
        </div>
      </Layout>
    </div>
  )
}
