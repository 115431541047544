// @ts-nocheck
/* eslint-disable no-empty-pattern */
import React, { useContext, useEffect } from "react"
import { Layout, Loader } from "../../components"
import { Divider, Grid } from "@mui/material"
import AppContext from "../../Context"
import moment from "moment"

export default function Notifications({}) {
  const { notifications, _getNotification, loading } = useContext(AppContext)
  useEffect(() => {
    _getNotification()
  }, [])

  return (
    <div>
      <Layout>
        <div className="container adjustMaxWidth minheight80vh">
          <Grid container spacing={2} className="rowBetween mb-4 ph-3">
            {loading && <Loader />}
            {!loading && notifications?.length === 0 && (
              <div className="text-div-center">
                You don't have any notification
              </div>
            )}
            {!loading &&
              notifications?.map((item, index) => (
                <Grid key={index} item xs={12}>
                  <div className="ph-0">
                    <div className="rowBetween align-items-center">
                      <div className="d-flex align-items-center">
                        <div className="notificationImage" />
                        <div>
                          <div className="font-bold">{item?.title}</div>
                          <div className="font-12">{item?.body}</div>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <span className="">
                          {moment(item?.created_at).fromNow()}
                        </span>
                      </div>
                    </div>
                    <Divider className="mt-4 mb-2" />
                  </div>
                </Grid>
              ))}
          </Grid>
        </div>
      </Layout>
    </div>
  )
}
