// @ts-nocheck
/* eslint-disable no-empty-pattern */
import React, { useContext, useState } from "react"
import {
  HomeFooter,
  AppInput,
  HomeHeader,
  AppButton,
  MainFooter
} from "../../components"
import { Element } from "react-scroll"
import { Divider, Grid, Paper, TextField } from "@mui/material"
import ReactStars from "react-stars"
import Bus1 from "../../assets/images/Bus1.png"
import stars from "../../assets/svg/stars.svg"
import bus from "../../assets/svg/bus.svg"
import price from "../../assets/images/price.png"
import people from "../../assets/svg/people.svg"
import headphone from "../../assets/svg/headphone.svg"
import { useNavigate } from "react-router-dom"
import AppContext from "../../Context"
import { COLORS } from "../../constants"

export default function MainHome({}) {
  const navigate = useNavigate()
  const { listRVS } = useContext(AppContext)
  const [state, setState] = useState({
    active: 0
  })

  const { active } = state

  const handleChange = (key, value) => {
    setState(pre => ({ ...pre, [key]: value }))
  }

  const types = [
    {
      image: stars,
      title: "Custodian Pricing",
      description:
        "With Custodian Pricing See The Same Values That Your Custodian and the IRS* use to value your portfolio."
    },
    {
      image: people,
      title: "Market Pricing",
      description:
        'With Market Pricing you Get "How Much Are My Coins Worth?".  Track the Value of Your Portfolio On the Open Market'
    },
    {
      image: headphone,
      title: "Get Live Offers",
      description:
        "Ready To Sell? Great! Submit Your Quantity and Coins You Want to Sell and Receive up to 5 Offers From Buyers Ready To Buy Today."
    }
  ]

  const handleSearch = () => {
    navigate("/recommandation")
  }

  return (
    <div>
      <HomeHeader home={true} />
      <Element name="Element1" className="element height75Mobile">
        <section className="top_banner">
          <div className="container height100 d-flex  justify-content-center align-items-center">
            <Grid container>
              <Grid item xs={12} md={5}>
                {/* <div className='text-left'> */}
                <p className={"text-white font-40 font-weight-bold"}>
                  Track Your Precious Metals Gold IRA Investment
                </p>
                <p className={"text-white font-14 mb-4"}>
                  Track the value of your precious metals IRA account. With
                  support for more than 75 American dealers, you can monitor
                  your values. Ready to Sell? Receive a real binding offer in 24
                  hours or less.
                </p>
                <AppButton
                  title={"Setup Your Portfolio"}
                  className={"mt-2"}
                  width={200}
                  height={40}
                  onClick={() => navigate("/login")}
                  color={COLORS.white}
                  backgroundColor={COLORS.primary}
                />
                {/* </div> */}
              </Grid>
            </Grid>
          </div>
        </section>
      </Element>

      <section className="howitwork">
        <div className="container">
          <div className="justify-content-center">
            <h1 className="font-24 text_primary mb-5 text-center">
              Track Your Metals In Real Time.
            </h1>
          </div>
          <Grid spacing={3} className="mb-5" container>
            {types?.map((rv, index) => (
              <Grid
                key={index}
                item
                md={4}
                sm={6}
                xs={12}
                sx={{ cursor: "pointer" }}
                // onClick={() => navigate(`/rv/${rv?.id}`)}
              >
                <div className="paperList">
                  <div className="paperHead">
                    <img src={price} className="imageBox" />
                    <div className="font-18 text_white">{rv.title}</div>
                  </div>
                  <div className="listallRVcard">
                    <div className="font-14">{rv.description}</div>
                    <AppButton
                      title={"Sign Up"}
                      width={100}
                      height={40}
                      className={"mt-3"}
                      onClick={() => navigate("/signup")}
                      color={COLORS.white}
                      backgroundColor={COLORS.primary}
                    />
                  </div>
                </div>
              </Grid>
            ))}
          </Grid>
          <div style={{ height: 200 }} />
        </div>
      </section>

      {/* <HomeFooter showCOntactUsMobile /> */}
      <MainFooter />
    </div>
  )
}
