import Cookies from "js-cookie"

export const getError = error => {
  const errorText = Object.values(error?.response?.data)
  if (errorText?.length > 0) {
    if (
      errorText[0] === "Invalid token" ||
      errorText[0] === "Token has expired" ||
      error?.response?.status === 401
    ) {
      localStorage.removeItem("token")
      localStorage.removeItem("user")
      //   Cookies.remove("")
      //   alert(`Token has expired, please login again`)
    } else {
      alert(`Error: ${JSON.stringify(errorText[0])}`)
    }
  } else {
    console.warn("error?.message", error?.message)
    alert(`Error: ${JSON.stringify(error?.message)}`)
  }
}

export const commafy = num => {
  console.log('num',Number(num?.toFixed(2)));
  const number = Number(num?.toFixed(2))
  return number?.toLocaleString("en-US")
}
