import { initializeApp } from "firebase/app"
import { getMessaging, getToken, onMessage } from "firebase/messaging"

var firebaseConfig = {
  apiKey: "AIzaSyAo_tTAABgPoqpAciYhlyRuoCpXcZy7_gs",
  authDomain: "goldiratracker.firebaseapp.com",
  projectId: "goldiratracker",
  storageBucket: "goldiratracker.appspot.com",
  messagingSenderId: "920810117630",
  appId: "1:920810117630:web:094e63eb70a1bea83e88af",
  measurementId: "G-40YN48CF0L"
}

const app = initializeApp(firebaseConfig)
const messaging = getMessaging(app)

export const requestForToken = async () => {
  try {
    const currentToken = await getToken(messaging, {
      vapidKey:
        "BP8NlhJ0i7oOSb5LVLGUmcnsZYKDEX0XUW0KkxNOy65CCIDGkfAkJIVQfRMP-YB0pkfaAM87EhLVAsNc6g2gcNE"
    })
    if (currentToken) {
      console.log("current token for client: ", currentToken)
    } else {
      console.log(
        "No registration token available. Request permission to generate one."
      )
    }
  } catch (err) {
    console.log("An error occurred while retrieving token. ", err)
  }
}
export const onMessageListener = () =>
  new Promise(resolve => {
    onMessage(messaging, payload => {
      resolve(payload)
    })
  })
