import "./App.css"
import React, { useEffect, useState } from "react"
import { Route, Routes, useNavigate } from "react-router-dom"
import { PrivateRoute } from "./components"
import { loadStripe } from "@stripe/stripe-js"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import { Elements } from "@stripe/react-stripe-js"

//ROUTES
import * as ROUTES from "./constants/routes"

//CONTAINERS
import {
  ContactUs,
  Home,
  PrivacyPolicy,
  RVDetails,
  TermsConditions,
  Login,
  MyPortfolio,
  AddCard,
  Pricing,
  AddPhotos,
  ForgotPassword,
  FeedBack,
  InviteLink,
  ChangePassword,
  UploadPhoto,
  Settings,
  Profile,
  Offers,
  InvoiceCreate,
  Favourite,
  Signup,
  Aboutus,
  Notifications,
  AddCoin,
  Upload,
  MainHome,
  Verfication,
  OffersPDF
} from "./containers"
import AppContext from "./Context"
import "./styles.css"
import {
  getAdvertisement,
  getAllPortfolio,
  getCoins,
  getCompany,
  getMetalTypes,
  getMyPortfolio,
  getNotification,
  getProfile,
  listOfOffers
} from "./api/auth"
import "react-date-range/dist/styles.css" // main style file
import "react-date-range/dist/theme/default.css" // theme css file
import { SnackbarProvider } from "notistack"
import AddCoinManually from "./containers/Booking/AddCoinManually"
import Notification from "./Notification"
import { getError } from "./utils"
const stripePromise = loadStripe(
  "pk_test_51LHszpICUZwLvblBOHgQGNgtQLWZVoQUelbi5JiK5e8rV4noTDSZ3DRCFPCoYyunryIL4OlDhwUFNAeJqKb0Lvlj00Hk8mUFpw"
)

const theme = createTheme()
function App() {
  const [loading, setLoading] = useState(false)
  const [user, setUser] = useState(null)
  const [notifications, setNotifications] = useState([])
  const [allPortfolio, setAllPortfolio] = useState([])
  const [myPortfolio, setMyPortfolio] = useState([])
  const [listRVS, setListRVS] = useState([])
  const [companies, setCompany] = useState([])
  const [allOffers, setAllOffers] = useState([])
  const [coins, setCoins] = useState([])
  const [metalTypes, setMetalTypes] = useState([])
  const [advertisement, setAdvertisement] = useState([])
  const navigate = useNavigate()
  let userData = localStorage.getItem("user")
  let token = localStorage.getItem("token")
  const isProtected = token

  useEffect(() => {
    if (token) {
      _getProfile()
      _getNotification()
      _getAllPortfolio()
      _getMyPortfolio()
      _listOfOffers()
      _getCoins()
      _getCompany()
    }
  }, [userData])

  const _getProfile = async () => {
    try {
      setLoading(true)
      const res = await getProfile()
      setUser(res?.data?.results?.length > 0 && res?.data?.results[0])
      setLoading(false)
    } catch (error) {
      setLoading(false)
      getError(error)
    }
  }

  const _getNotification = async () => {
    try {
      setLoading(true)
      const res = await getNotification()
      setNotifications(res?.data?.results)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      getError(error)
    }
  }

  const _getMyPortfolio = async () => {
    try {
      setLoading(true)
      const res = await getMyPortfolio()
      setMyPortfolio(res?.data?.results)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      getError(error)
    }
  }

  const _getAllPortfolio = async () => {
    try {
      setLoading(true)
      const res = await getAllPortfolio()
      setAllPortfolio(res?.data?.results)
      setLoading(false)
    } catch (error) {
      getError(error)
    }
  }
  const _getAdvertisement = async () => {
    try {
      setLoading(true)
      const res = await getAdvertisement()
      setAdvertisement(res?.data?.results)
      setLoading(false)
    } catch (error) {
      getError(error)
    }
  }

  const _listOfOffers = async () => {
    try {
      setLoading(true)
      const res = await listOfOffers()
      setAllOffers(res?.data?.results)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      getError(error)
    }
  }

  const _getCoins = async () => {
    try {
      setLoading(true)
      const res = await getCoins()
      const res1 = await getMetalTypes()
      setCoins(res?.data?.results)
      setMetalTypes(res1?.data?.results)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      getError(error)
    }
  }

  const _getCompany = async () => {
    try {
      setLoading(true)
      const res = await getCompany()
      setCompany(res?.data?.results)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      getError(error)
    }
  }

  return (
    <AppContext.Provider
      value={{
        user,
        setUser,
        _getProfile,
        listRVS,
        notifications,
        _getNotification,
        allPortfolio,
        _getAllPortfolio,
        myPortfolio,
        _getMyPortfolio,
        _listOfOffers,
        allOffers,
        coins,
        _getCoins,
        _getCompany,
        companies,
        metalTypes,
        loading,
        advertisement,
        _getAdvertisement
      }}
    >
      <SnackbarProvider>
        <Elements stripe={stripePromise}>
          <ThemeProvider theme={theme}>
            <Notification />
            <Routes>
              <Route path={ROUTES.HOME} element={<MainHome />} />
              <Route
                path={ROUTES.MAIN}
                element={
                  <PrivateRoute>
                    <Home />
                  </PrivateRoute>
                }
              />
              <Route
                path={ROUTES.MAIN1}
                element={
                  <PrivateRoute>
                    <Home />
                  </PrivateRoute>
                }
              />
              <Route
                path={ROUTES.MAIN2}
                element={
                  <PrivateRoute>
                    <Home />
                  </PrivateRoute>
                }
              />
              <Route path={ROUTES.LOGIN} element={<Login />} />
              <Route path={ROUTES.SIGNUP} element={<Signup />} />
              <Route path={ROUTES.VERIFICATION} element={<Verfication />} />
              <Route
                path={ROUTES.FORGOTPASSWORD}
                element={<ForgotPassword />}
              />
              <Route
                path={ROUTES.CHANGEPASSWORD}
                element={
                  <PrivateRoute>
                    <ChangePassword />
                  </PrivateRoute>
                }
              />
              <Route
                path={ROUTES.FEEDBACK}
                element={
                  <PrivateRoute>
                    <FeedBack />
                  </PrivateRoute>
                }
              />
              <Route path={ROUTES.INVITELINK} element={<InviteLink />} />
              <Route path={ROUTES.UPLOADPHOTO} element={<UploadPhoto />} />
              <Route
                path={ROUTES.SETTINGS}
                element={
                  <PrivateRoute>
                    <Settings />
                  </PrivateRoute>
                }
              />
              <Route path={ROUTES.RVDETAILS} element={<RVDetails />} />
              <Route path={ROUTES.CONTACTUS} element={<ContactUs />} />
              <Route
                path={ROUTES.TERMSCONDITIONS}
                element={<TermsConditions />}
              />
              <Route path={ROUTES.ABOUTUS} element={<Aboutus />} />
              <Route path={ROUTES.PRIVACYPOLICY} element={<PrivacyPolicy />} />
              <Route
                path={ROUTES.UPLOAD_PORTFOLIO}
                element={
                  <PrivateRoute>
                    <Upload />
                  </PrivateRoute>
                }
              />
              <Route
                path={ROUTES.ADD_COIN_MANUALLY}
                element={
                  <PrivateRoute>
                    <AddCoinManually />
                  </PrivateRoute>
                }
              />
              <Route
                path={ROUTES.MY_PORTFOLIO}
                element={
                  <PrivateRoute>
                    <MyPortfolio />
                  </PrivateRoute>
                }
              />
              <Route
                path={ROUTES.ADD_PORTFOLIO}
                element={
                  <PrivateRoute>
                    <AddCoin />
                  </PrivateRoute>
                }
              />
              <Route
                path={ROUTES.ADDCARD}
                element={
                  <PrivateRoute>
                    <AddCard />
                  </PrivateRoute>
                }
              />
              {/* <Route path={ROUTES.LISTRVS} element={<ListRVs />} /> */}
              {/* <Route path={ROUTES.ADDDETAILS} element={<AddDetails />} /> */}
              {/* <Route path={ROUTES.LISTING} element={<Listing />} /> */}
              <Route
                path={ROUTES.PRICING}
                element={
                  <PrivateRoute>
                    <Pricing />
                  </PrivateRoute>
                }
              />
              <Route
                path={ROUTES.ADDPHOTOS}
                element={
                  <PrivateRoute>
                    <AddPhotos />
                  </PrivateRoute>
                }
              />
              <Route
                path={ROUTES.PROFILE}
                element={
                  <PrivateRoute isAuthenticated={isProtected}>
                    <Profile />
                  </PrivateRoute>
                }
              />
              <Route
                path={ROUTES.OFFERS}
                element={
                  <PrivateRoute>
                    <Offers />
                  </PrivateRoute>
                }
              />
              <Route
                path={ROUTES.OFFERSPDF}
                element={
                  <PrivateRoute>
                    <OffersPDF />
                  </PrivateRoute>
                }
              />
              <Route
                path={ROUTES.NOTIFICATIONS}
                element={
                  <PrivateRoute>
                    <Notifications />
                  </PrivateRoute>
                }
              />
              <Route
                path={ROUTES.INVOICECREATE}
                element={
                  <PrivateRoute>
                    <InvoiceCreate />
                  </PrivateRoute>
                }
              />
              <Route
                path={ROUTES.FAVOURITE}
                element={
                  <PrivateRoute>
                    <Favourite />
                  </PrivateRoute>
                }
              />
            </Routes>
          </ThemeProvider>
        </Elements>
      </SnackbarProvider>
    </AppContext.Provider>
  )
}

export default App
