// @ts-nocheck
/* eslint-disable no-empty-pattern */
import React, { useEffect, useState } from "react"
import { AppButton, AppInput, HomeHeader, MainFooter } from "../../components"
import { Grid, Typography } from "@mui/material"
import eyeIcon from "../../assets/svg/eye.svg"
import eyeCLose from "../../assets/images/closeEYE.jpg"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import { loginUser, resendOTP, verifyEmail } from "../../api/auth"
import AppContext from "../../Context"
import { useContext } from "react"
import { COLORS } from "../../constants"
import { useSnackbar } from "notistack"
import OtpInput from "react-otp-input"

export default function Verfication({}) {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const email = localStorage.getItem("email")
  const path = window.location.pathname
  const [state, setState] = useState({
    otp: "",
    loading: false
  })

  // useEffect(() => {
  //   if (path) {
  //     handleChange("activeTab", path === "/signup" ? 0 : 1)
  //   }
  //   if (user) {
  //     navigate("/")
  //   }
  // }, [path, user])

  const { loading, otp } = state

  const handleChange = (key, value) => {
    setState(pre => ({
      ...pre,
      [key]: value
    }))
  }

  const handleLogin = async () => {
    try {
      handleChange("loading", true)
      const payload = {
        email,
        token: otp
      }
      await verifyEmail(payload)
      handleChange("loading", false)
      enqueueSnackbar(`Email has been verified`, {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
      localStorage.removeItem("email")
      navigate("/login")
    } catch (error) {
      handleChange("loading", false)
      const errorText = Object.values(error?.response?.data)
      if (errorText.length > 0) {
        alert(`Error: ${errorText[0]}`)
      } else {
        alert(`Error: ${error}`)
      }
    }
  }

  const handleResend = async () => {
    try {
      handleChange("loading", true)
      const payload = {
        email
      }
      await resendOTP(payload)
      handleChange("loading", false)
      enqueueSnackbar(`Email has been sent`, {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    } catch (error) {
      handleChange("loading", false)
      const errorText = Object.values(error?.response?.data)
      if (errorText.length > 0) {
        alert(`Error: ${errorText[0]}`)
      } else {
        alert(`Error: ${error}`)
      }
    }
  }

  return (
    <div>
      <HomeHeader />
      <section className="bg-white">
        <div className="container divCenter loginContainer">
          <Grid container className='mobileFullWidth' justifyContent={"center"}>
            <Grid item xs={12} sm={10} md={6} lg={4} className="loginPaper mtm-3">
              <div className="text_primary text-center font-18 font-bold">
                Verfication
              </div>
              <OtpInput
                value={otp}
                onChange={otp => handleChange("otp", otp)}
                numInputs={6}
                containerStyle={{ marginTop: 20, marginBottom: 20 }}
                inputStyle={{
                  width: "100%",
                  height: 50,
                  border: "1px solid #d3d3d3",
                  borderRadius: 10
                  // marginRight: 10
                }}
                separator={
                  <span style={{ marginRight: 5, marginLeft: 5 }}></span>
                }
              />
              <div className=" mb-4 mt-2">
                <div className="checkboxDiv ">
                  <Typography variant="body2" className="text_primary06 mr-2">
                    Didn't receive?
                  </Typography>
                  <div className="c-pointer" onClick={handleResend}>
                    <p className="text_secondary">Resend code</p>
                  </div>
                </div>
              </div>
              <AppButton
                title={"Verify"}
                onClick={handleLogin}
                loading={loading}
                disabled={!otp}
                backgroundColor={COLORS.primary}
                color={"#fff"}
              />
            </Grid>
          </Grid>
        </div>
      </section>
      <div style={{ height: 200 }} />
      <MainFooter />
    </div>
  )
}
