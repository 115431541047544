// @ts-nocheck
/* eslint-disable no-empty-pattern */
import React, { useEffect, useState } from "react"
import { Layout, Loader } from "../../components"
import { Grid, Divider, Button } from "@mui/material"
import { useNavigate } from "react-router-dom"
import AppContext from "../../Context"
import { useContext } from "react"
import AppTable from "../../components/AppTable"
import { deletePortfolio } from "../../api/auth"
import { commafy, getError } from "../../utils"

const headCells1 = [
  {
    id: "image",
    numeric: false,
    disablePadding: true,
    sx: { width: 400 },
    label: "Name"
  },
  {
    id: "spot_price",
    numeric: false,
    disablePadding: false,
    label: "Current Price"
  },
  {
    id: "owned",
    numeric: false,
    disablePadding: false,
    label: "Quantity"
  },
  {
    id: "total",
    numeric: false,
    disablePadding: false,
    label: "Total"
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: ""
  }
]

export default function MyPortfolio({ }) {
  const navigate = useNavigate()
  const { myPortfolio, loading, _getMyPortfolio } = useContext(AppContext)
  const [state, setState] = useState({
    loadingDelete: false,
    categories: "",
    searchText: ""
  })

  const { loadingDelete } = state

  useEffect(() => {
    _getMyPortfolio()
  }, [])

  const handleChange = (key, value) => {
    setState(pre => ({
      ...pre,
      [key]: value
    }))
  }

  const handleDelete = async id => {
    try {
      handleChange("loadingDelete", true)
      await deletePortfolio(id)
      _getMyPortfolio()
      handleChange("loadingDelete", false)
    } catch (error) {
      handleChange("loadingDelete", false)
      getError(error)
    }
  }

  const getGrandTotal = () => {
    var total = 0
    myPortfolio?.forEach(element => {
      total =
        total +
        Number(element?.coins?.metal_type?.price) *
        Number(element?.coins?.weight) *
        Number(element?.owned)
    })
    return total
  }

  return (
    <div>
      <Layout>
        <div className="container adjustMaxWidth minheight80vh">
          <Grid container justifyContent={"center"}>
            <Grid item xs={12} sm={12} md={12} className="p-2">
              <Grid
                container
                justifyContent={"space-between"}
                className={"mb-3"}
                alignItems={"center"}
              >
                <p
                  className="text_black font-bold font-16 mobileText"
                >
                  Precious Metals Spot Values
                </p>
                <Grid container justifyContent={"flex-end"} item md={6}>
                  <Button
                    variant="contained"
                    style={{
                      textTransform: "capitalize",
                      marginBottom: 10,
                      marginTop: -20
                    }}
                    onClick={() => navigate("/dashboard/portfolio/add")}
                  >
                    Add New Coin
                  </Button>
                </Grid>
              </Grid>
              <Divider />
              <Grid container spacing={2} className={"mt-3"}>
                {loading ? (
                  <Loader />
                ) : (
                  <AppTable
                    onClickItem={() => navigate("/my-booking/details")}
                    headCells={headCells1}
                    portfolio
                    pending
                    rows={myPortfolio}
                    grandTotal={commafy(getGrandTotal())}
                    handleDelete={handleDelete}
                    loadingDelete={loadingDelete}
                    rowsPage={15}
                  />
                )}
                {/* <Grid container justifyContent={"flex-end"} className="mr-15">
                  <div>
                    <b>Grand Total:</b> ${commafy(getGrandTotal())}
                  </div>
                </Grid> */}
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Layout>
    </div>
  )
}
